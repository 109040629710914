import React from "react";
import BlockContent from "@sanity/block-content-to-react"
import customBlocks from "../../customBlocks/customBlocks"
import style from "./index.module.css";

function QuotationList(props: GatsbyTypes.SanityQuotationList, title) {
  return (
    <div>
      <div className={style.main}>
        {props.title &&
          <h1 className={style.title}>{props.title}</h1>
        }
        {props.text &&
          <div className={style.text}>
            <BlockContent blocks={props.text} serializers={customBlocks} />
          </div>
        }
      </div>

      <div className={style.list}>
        {props.quotationList.map((quotation) => {
          return(
            <article className={style.quotation} key={quotation._key}>
              {quotation.title &&
                <h1 className={style.quotationTitle}>{quotation.title}</h1>
              }
              {quotation.text &&
                <div className={style.quotationText}>
                  <BlockContent blocks={quotation.text} serializers={customBlocks} />
                </div>
              }
              {quotation.lines &&
                <table className={style.table}>
                  <tbody>
                    {quotation.lines.map((line, index) => (
                      <tr className={style.row} key={index}>
                        <td className={style.description}>{line.description}</td>
                        <td className={style.amount}>{line.amount}</td>
                      </tr>
                    ))}
                    <tr className={style.total}>
                      <td className={style.description}>Totaal</td>
                      <td className={style.amount}>{quotation.total}</td>
                    </tr>
                  </tbody>
                </table>
              }
            </article>
          )
        })}
      </div>
      {props.disclaimer &&
        <div className={style.disclaimer}>{props.disclaimer}</div>
      }
    </div>
  )
}

export default QuotationList