import Img from "gatsby-image"
import React from "react";
import BlockContent from "@sanity/block-content-to-react"
import customBlocks from "../../../components/customBlocks/customBlocks"
import style from "./index.module.css";
import clientConfig from "../../../../client-config";

import { getFluidGatsbyImage } from "gatsby-source-sanity";

function PageBlockImage(props) {
  const title =  props.title
  const content:GatsbyTypes.SanityPageBlockImage = props.content
  const fluidProps = content.image && getFluidGatsbyImage(content.image.asset._id, {maxWidth: 1200}, clientConfig.sanity)
  return (
    <div className={style.main}>
      {content.image &&
        <div className={style.imageContainer}>
          <div className={style.image}>
              <Img fluid={fluidProps} className={style.imageWrapper} alt={content.image.alt} />
          </div>
        </div>
      }
      {content.mainText &&
        <div className={style.content}>
          {title &&
            <h1 className={style.subtitle}>{title}</h1>
          }
          {content.mainText.title &&
            <div className={style.title}>{content.mainText.title}</div>
          }
          {content.mainText.lead &&
            <div className={style.lead}>{content.mainText.lead}</div>
          }
          {content.mainText.body &&
            <BlockContent blocks={content.mainText.body} serializers={customBlocks} />
          }
        </div>
      }
      {content.quote &&
        <div className={style.callToAction}>
          <BlockContent blocks={content.quote} serializers={customBlocks} />
        </div>
      }
      {content.explainer &&
        <div className={style.explainer}>
          <BlockContent blocks={content.explainer} serializers={customBlocks} />
        </div>
      }
    </div>
  )
}

export default PageBlockImage

