import React from "react";
import BlockContent from "@sanity/block-content-to-react"
import customBlocks from "../../customBlocks/customBlocks"
import style from "./index.module.css";

function PageBlockText(props: GatsbyTypes.SanityPageBlockText) {
  return (
    <div className={style.main}>
      <div className={style.content}>
        {props.title &&
          <h1 className={style.title}>{props.title}</h1>
        }
        {props.lead &&
          <div className={style.lead}>{props.lead}</div>
        }
        {props.body &&
          <BlockContent blocks={props.body} serializers={customBlocks} />
        }
      </div>
    </div>
  )
}

export default PageBlockText

