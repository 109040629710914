import { Link } from "gatsby";
import React from "react";
import BlockContent from "@sanity/block-content-to-react"
import customBlocks from "../../../components/customBlocks/customBlocks"
import style from "./index.module.css";

function InfoBlocks(props: GatsbyTypes.SanityInfoBlocks, title) {
  return (
    <div className={style.main}>
      <div className={style.blocks}>
        {props.infoBlocks.map((block) => {
          return(
            <article className={style.block} key={block._key}>
              {(block.icon && block.icon.asset) &&
                <img className={style.icon} src={block.icon.asset.url} alt={block.title} title={block.title} />
              }
              <div>
                {block.title &&
                  <h1 className={style.title}>{block.title}</h1>
                }
                {block.subtitle &&
                  <div className={style.subtitle}>{block.subtitle}</div>
                }
                {block.text &&
                  <BlockContent blocks={block.text} serializers={customBlocks} />
                }
              </div>
              {block.page &&
                <Link className={style.link} to={`/${block.page.slug.current}/`}>{block.linkText}</Link>
              }
            </article>
          )
        }
        )}
      </div>
    </div>
  )
}

export default InfoBlocks