import React from "react";
import PageBlockImage from "./PageBlockImage";
import InfoBlocks from "./InfoBlocks";
import PageBlockText from "./PageBlockText";
import QuotationList from "./QuotationList";
import PageBlockContact from "./PageBlockContact";

interface IPageProps {
  data: GatsbyTypes.PageQuery;
}

function PageBlocks({page}) {
  const contentBlocks = (page._rawContent || [])
    .map((c, i) => {
      let el = null;
      switch (c._type) {
        case "pageBlockImage":
          el = <PageBlockImage key={c._key} title={page.title} content={c} />;
          break;
        case "infoBlocks":
          el = <InfoBlocks key={c._key} title={page.title} {...c} />;
          break;
        case "pageBlockText":
          el = <PageBlockText key={c._key} title={page.title} {...c} />;
          break;
        case "quotationList":
          el = <QuotationList key={c._key} title={page.title} {...c} />;
          break;
        case "pageBlockContact":
          el = <PageBlockContact key={c._key} title={page.title} content={c} />;
          break;
        default:
          el = null;
      }
      return el;
    });

  return (
    <div>
      {contentBlocks}
    </div>
  )
}

export default PageBlocks